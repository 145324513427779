import { ContextApi } from 'contexts/Localization/types'

import {
  DropdownMenuItemType,
  MenuItemsType,
} from '@pancakeswap/uikit'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: 'Swap',
    href: '/swap',
    showItemsOnMobile: false,
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Earn'),
    href: '/farms',
    icon: 'Earn',
    items: [
      {
        label: t('Farms'),
        href: '/farms',
      },
      {
        label: t('Pools'),
        href: '/pools',
      },
    ],
  },
  {
    label: t('More'),
    icon: 'More',
    href:'#',
    items: [
      
      {
        label: t('Website'),
        href: 'https://alveychain.com/',
        type:DropdownMenuItemType.EXTERNAL_LINK
      }
      ,
      {
        label: t('Whitepaper'),
        href: 'https://www.alveychain.com/documents/Alvey-Chain-White-Paper-V1.1-1.pdf',
        type:DropdownMenuItemType.EXTERNAL_LINK
      }
      ,
      {
        label: t('Oculus'),
        href: 'https://www.oculus.com/deeplink/?action=view&path=%2Fprofile%2F105412678873935&utm_medium=share&utm_source=oculus',
        type:DropdownMenuItemType.EXTERNAL_LINK
      },
      {
        label: t('Gitbook'),
        href: 'https://alvey-chain.gitbook.io/magic-book/',
        type:DropdownMenuItemType.EXTERNAL_LINK
      },
      {
        label: t('Pitch'),
        href: 'https://www.alveychain.com/documents/Alvey-Chain-Pitch-Deck.pdf',
        type:DropdownMenuItemType.EXTERNAL_LINK
      },
      {
        label: t('Youtube'),
        href: 'https://www.youtube.com/watch?v=U2BQegSqjAA',
        type:DropdownMenuItemType.EXTERNAL_LINK
      },
      {
        label: t('Explorer'),
        href: 'https://www.alveyscan.com',
        type:DropdownMenuItemType.EXTERNAL_LINK
      }
      ,
      {
        label: t('Linktree'),
        href: 'https://linktr.ee/alveychain',
        type:DropdownMenuItemType.EXTERNAL_LINK
      }
      
    ],
  }
]

export default config
