import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Alvey Swap',
  description:
    'The most popular AMM on BSC by user count! Earn ALV through yield farming or win it in the Lottery, then stake it in ALV Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Alvey Swap), NFTs, and more, on a platform you can trust.',
  image: 'https://alveyswap.app/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Alvey Swap')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('Alvey Swap')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('Alvey Swap')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('Alvey Swap')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('Alvey Swap')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('Alvey Swap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Alvey Swap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Alvey Swap')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('Alvey Swap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Alvey Swap')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('Alvey Swap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Alvey Swap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Alvey Swap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Alvey Swap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Alvey Swap')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('Alvey Swap')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('Alvey Swap')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('Alvey Swap')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('Alvey Swap Info & Analytics')}`,
        description: 'View statistics for Alvey Swap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('Alvey Swap Info & Analytics')}`,
        description: 'View statistics for Alvey Swap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('Alvey Swap Info & Analytics')}`,
        description: 'View statistics for Alvey Swap exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('Alvey Swap')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('Alvey Swap')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')} | ${t('Alvey Swap')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')} | ${t('Alvey Swap')}`,
      }
    default:
      return null
  }
}
